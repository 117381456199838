import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Menu from "../components/Menu"
import MobileMenu from "../components/MobileMenu"
import Slider from "react-slick"
import "@brainhubeu/react-carousel/lib/style.css"
import "../styles/global.scss"
import * as styles from "../styles/news-details.module.scss"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { SEO } from "../components/SEO"

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined"

const NewsDetails = ({ data }) => {
  const [width, setWidth] = React.useState(isBrowser ? window.innerWidth : 0)

  const [images] = React.useState(
    data.featuredImgs.nodes.map(node => (
      <GatsbyImage
        image={node.childImageSharp.gatsbyImageData}
        alt={node.name}
      />
    ))
  )

  React.useEffect(() => {
    function handleResize() {
      if (isBrowser) setWidth(window.innerWidth)
    }
    if (isBrowser) window.addEventListener("resize", handleResize)
  })

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  }

  return isBrowser ? (
    <div className={styles.wrapper}>
      {width >= 1220 ? <Menu type="news" /> : <MobileMenu type="news" />}
      <div className={styles.container}>
        <div className={styles.infoContainer}>
          <h1 className={styles.header}>
            {data.markdownRemark.frontmatter.title}
          </h1>
          <Slider {...settings}>{images}</Slider>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: data.markdownRemark.html,
            }}
          />
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

export const Head = ({ data, pageContext }) => {
  return (
    <SEO
      title={"Onarland - " + data.markdownRemark.frontmatter.title}
      description={data.markdownRemark.frontmatter.description}
      language={pageContext.language === "en" ? "en-gb" : "gr"}
    />
  )
}

export const query = graphql`
  query NewsDetails(
    $language: String!
    $type: String
    $pagePath: String
    $imagesPath: String
  ) {
    markdownRemark(
      frontmatter: {
        type: { eq: $type }
        slug: { eq: $pagePath }
        language: { eq: $language }
      }
    ) {
      frontmatter {
        title
        description
      }
      html
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    featuredImgs: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativePath: { regex: $imagesPath }
      }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: TRACED_SVG)
        }
      }
    }
  }
`

export default NewsDetails
